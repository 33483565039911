




import { Component, Vue } from "vue-property-decorator";
import PageBreadCrumb from "@/components/PageBreadCrumb.vue";

@Component({
  components: {
    PageBreadCrumb
  }
})
export default class DataProtection extends Vue {}
